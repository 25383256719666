export default {
  GARAGE_SIZE: '<span class="garage-size head-slot-cart-link-quantity" key="garage-size">{{size}}</span>',
  GARAGE_TITLE: 'Title',

  PRODUCT_REPEATER_CLASS: 'grid product-grid grid-2-col grid-3-col-tablet grid-4-col-desktop',
  RELATED_PRODUCT_LIST_CLASS: 'grid product-grid grid-2-col grid-3-col-tablet grid-4-col-desktop',
  FACET_TITLE_ICON: `
  <rt-virtual rt-if="this.isCollapsed" template="icons/angle-down" />
  <rt-virtual rt-if="!this.isCollapsed" template="icons/minus" />`,
};
